import React from "react";
import {HeadFC} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {SEO} from "../components/seo";

const InsurancePackagesPage = () => {
  return (
    <>
      <Header/>
      <main className="h-full">
        <section className="h-full sm:h-1/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 font-poppins">
              Insurance Packages
            </h1>
          </div>
        </section>
        <section className="w-full bg-white py-20 px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl">
              <b>Insurance</b>
            </h2>
            <p className="mt-2">
              Car rental insurance is essential, and we offer a range of packages to meet your needs during your stay on
              Paros Island.
            </p>
            <p>
              Our cars are insured against theft, broken glass, third party damage except for a) driver and passengers
              b) our own vehicle.</p>
            <br/>
            <h3 className="text-l">
              <b>1) CDW-Collision Damage Waiver.</b>
            </h3>
            <p className="mt-2">The renter is covered for any damage to the rental vehicle:</p>
            <ul className="list-disc ml-8 mt-2">
              <li>over € 500 + VAT for car category A, B, C (10 € / day)</li>
              <li>over € 900 + VAT for car category D, SUV (15 € / day)</li>
            </ul>
            <p className="mt-2">To qualify for coverage, you must comply with local traffic laws and drive legally. Please note that the
              insurance does not cover tire damage, damage to the underside of the vehicle, or the loss or theft of
              personal belongings.</p>
            <br/>
            <h3 className="text-l"><b>2) ECDW-Extras Collision Damage Waiver.</b></h3>
            <p className="mt-2">The renter is covered for any damage to the rental vehicle:</p>
            <ul className="list-disc ml-8 mt-2">
              <li>over 300 € + VAT&nbsp; for car category A, B, C (15 € / day)</li>
              <li>over 500 € + VAT for car category D, SUV (20 € / day)</li>
            </ul>
            <p className="mt-2">
              Again, you must drive legally and follow local traffic laws to qualify for coverage. Tire damage, damage
              to the underside of the vehicle, and the loss or theft of personal belongings are not covered.
            </p>
            <br/>
            <h3 className="text-l"><b>3) FDW-Full Damage Waiver.</b></h3>
            <p className="mt-2">The renter is covered for any damage to the rental vehicle:</p>
            <ul className="list-disc ml-8 mt-2">
              <li>For car category A, B, C (20 € / day)</li>
              <li>For car category D, SUV (30 € / day)</li>
            </ul>
            <p className="mt-2">
              As always, you must comply with local traffic laws and drive legally. Please note that tire damage,
              damage to the underside of the vehicle, and the loss or theft of personal belongings are not covered.
            </p>
            <br/>
            <h3 className="text-l"><b>4) SDW-Full Damage Waiver.</b></h3>
            <p className="mt-2">The renter is covered for any damage to the rental vehicle (<b>including damage to the underside of the vehicle</b>):</p>
            <ul className="list-disc ml-8 mt-2">
              <li>For car category A, B, C (30 € / day)</li>
              <li>For car category D, SUV (40 € / day)</li>
            </ul>
            <p className="mt-2">
              As always, you must comply with local traffic laws and drive legally. Please note that loss
              or theft of personal belongings are not covered.
            </p>
            <br/>
            <p>
              Our insurance programs provide you with the level of protection that best suits your needs, with reduced
              deductible and total coverage. Book your rental car on Paros Island today and enjoy peace of mind during
              your travels.
            </p>
          </div>
        </section>
      </main>
      <Footer/>
    </>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="Car Rental Insurance for Paros Island | Coolcar Rentals"
    description="Choose CDW, ECDW, FDW, SDW for car rental insurance on Paros Island with Coolcar. Drive worry-free knowing you're covered for theft, damage, and more. Book now!"
  />
);

export default InsurancePackagesPage;